import {Option} from "prelude-ts";

import {
  ApiConfig,
  AuthConfig,
  Config,
} from "./interface";

const domains = window.location.hostname.split(".");

const isRootDeploy = !!(process.env.IS_ROOT_DEPLOY);

const customerId = domains[0];
const rootDomain = domains.slice( isRootDeploy ? 0 : 1).join(".");

const rootUrl = (): URL => {
  const url = new URL(window.location.href);
  url.hostname = rootDomain;

  // TODO: This is a hack to support local env. Remove when local is reconfigured to match dev and prod.
  if (url.hostname.endsWith("localhost")) {
    url.hostname = "localhost";
  }
  // <- hacks ends here

  url.pathname = "";
  url.search = "";
  return url;
};

const subdomainUrl = (subdomain: string, url: URL): URL => {
  const urln = new URL(url.href);
  urln.hostname = `${subdomain}.${urln.hostname}`;
  return urln;
};

const rootSubdomainUrl = (subdomain: string): URL => {
  return subdomainUrl(subdomain, rootUrl());
};


const api: ApiConfig = {
  messenger: {
    rest: () => {

      // TODO: This is a hack to support local env. Remove when local is reconfigured to match dev and prod.
      const urltmp = rootUrl();
      if (urltmp.hostname.endsWith("localhost")) {
        return new URL("https://api-ui.textkit.ca.dev.stflo.io");
      }
      // <- hack ends here

      return rootSubdomainUrl("api-ui");

    },
    graphql: () => {

      // TODO: This is a hack to support local env. Remove when local is reconfigured to match dev and prod.
      const urltmp = rootUrl();
      if (urltmp.hostname.endsWith("localhost")) {
        return new URL("https://api-ui.textkit.ca.dev.stflo.io/graphql");
      }
      // <- hack ends here

      const url = rootSubdomainUrl("api-ui");
      url.pathname = "/graphql";
      return url;
    },
    websocket: () => {
      // TODO: This is a hack to support local env. Remove when local is reconfigured to match dev and prod.

      const urltmp = rootUrl();
      if (urltmp.hostname.endsWith("localhost")) {
        return new URL("wss://api-ui.textkit.ca.dev.stflo.io/subscriptions");
      }
      // <- hack ends here

      const url = rootSubdomainUrl("api-ui");
      url.protocol = "wss";
      url.pathname = "/subscriptions";
      return url;
    },
  },
  admin: {
    graphql: () => {

      // TODO: This is a hack to support local env. Remove when local is reconfigured to match dev and prod.
      const urltmp = rootUrl();
      if (urltmp.hostname.endsWith("localhost")) {
        return new URL("https://api.textkit.ca.dev.stflo.io/graphql");
      }
      // <- hack ends here

      const url = rootSubdomainUrl("api");
      url.pathname = "/graphql";
      return url;

    },
  },
};

const config: Config = {
    customerId: () => customerId,
    workspaceUrl:
      (team: string): URL => {

        const url = rootUrl();
        // TODO: This is a hack to support local env without full routing.
        if (url.hostname.endsWith("localhost")) {
          url.port = "3000";
        }
        // <- hacks end here

        return subdomainUrl(team, url);
      },
    workspaceSelectorUrl:
      (): URL => {

        const url = rootUrl();
        // TODO: This is a hack to support local env without full routing.
        if (url.hostname.endsWith("localhost")) {
          url.port = "3030";
        }
        // <- hacks end here
        return url;
      },

    authConfig: (): Promise<Option<AuthConfig>> => ((): Promise<Option<AuthConfig>> =>
      fetch(`${api.messenger.rest().origin}/auth0?customer=${customerId}&type=messenger`)
        .then(
          async (response): Promise<Option<AuthConfig>> => {
            const json = await response.json();
            return Option.of({
              domain: json.domain,
              client_id: json.clientId,
              audience: json.audience,
              redirect_uri: new URL(window.location.origin).toString(),
            });
          },
        ).catch(
        async (): Promise<Option<AuthConfig>> => Option.none<AuthConfig>(),
      )
    )(),

    api,
};

export default config;
