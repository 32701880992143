import development from "./development";
import { Config } from "./interface";
import production from "./production";

const get = (): Config => {
    if (process.env.TARGET === "production") {
        return production;
    } 
        return development;
    
};

export const config = get();
export default get();
