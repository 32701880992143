import "./TypedMessage.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import icons from "@/components/icons";

import { MessageType } from "../../enums";

interface TypedMessageProps {
    /** MessageType { WARNING = 'warning', SUCCESS = 'success', INFO    = 'info', ERROR   = 'error' } */
    type: MessageType;
    children: string | React.ReactElement;
    className?: string;
}

class TypedMessage extends PureComponent<TypedMessageProps> {
    baseClassName = "c-typed-message";

    getIcon = (): React.ReactNode => {
        switch (this.props.type) {
            case MessageType.SUCCESS:
                return icons.SUCCESS;
            case MessageType.ERROR:
                return icons.ERROR;
            case MessageType.INFO:
                return icons.INFO;
            case MessageType.WARNING:
                return icons.WARNING;
            default:
                return null;
        }
    };

    getClassName(): string {
        const c = this.baseClassName;
        return cx(c, this.props.className, `${c}--${this.props.type}`);
    }

    render(): React.ReactNode {
        return (
            <div className={this.getClassName()}>
                <span className={`${this.baseClassName}__icon`}>{this.getIcon()}</span>
                <span className={`${this.baseClassName}__message`}>{this.props.children}</span>
            </div>
        );
    }
}

export default TypedMessage;
