import "./Login.scss";
import "@ui-lib/styles/screen.scss";

import { setTheme } from "@ui-lib/whiteLabel/whiteLabel.service";
import { DEFAULT_THEME } from "@ui-lib/whiteLabel/whiteLabel.settings";
import React from "react";

import Partner from "./pages/Partner";

const Login = (): React.ReactElement => {
    const loadedTheme = DEFAULT_THEME;
    setTheme(loadedTheme);
    return <div className="c-login"><Partner theme={loadedTheme} /></div>;
};

export default Login;
