import "./index.scss";

import React, { ReactFragment } from "react";

interface HeadingProps {
    title: ReactFragment | string;
    subtitle: ReactFragment | string;
}

const Heading = (props: HeadingProps): React.ReactElement => (
    <div className="c-heading">
        {props.title && <span className="c-heading__title">{props.title}</span>}
        {props.subtitle && <span className="c-heading__subtitle">{props.subtitle}</span>}
    </div>
);

export default Heading;
