import { TTheme } from "@/components/whiteLabel/whiteLabel.types";

export const DEFAULT_THEME: TTheme = {
    // logoType: 'https://www.statflo.com/assets/img/svg/logo.svg',
    logotype: null,
    backgroundColor: {
        hue: 0,
        saturation: 0,
        lightness: 100,
    },
    primaryColor: {
        hue: 229.3,
        saturation: 63.6,
        lightness: 56.9,
    },
    sidebarColor: {
        hue: 0,
        saturation: 0,
        lightness: 10.2,
    },
    textColor: {
        hue: 0,
        saturation: 0,
        lightness: 17.6,
    },
};
