import "./index.scss";

import React, {useEffect} from "react";

interface WorkspaceInputProps {
    domain: string;
    placeholder: string;
    onChange: (workspace: string) => void;
}

const WorkspaceInput = (props: WorkspaceInputProps): React.ReactElement => {

    const ref = React.createRef<HTMLInputElement>();
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => props.onChange(e.target.value);

    useEffect(() => {
        ref.current.focus();
    }, []);


  return (
        <div className="c-workspace-input">
            <input
                ref={ref}
                className="c-workspace-input__space"
                type="text"
                placeholder={props.placeholder}
                onChange={onChange}
            />
            <span className="c-workspace-input__domain">{props.domain}</span>
        </div>
    );
};

export default WorkspaceInput;
