export const ellipsis = (text: string, size: number): string =>
    text && text.length > size ? `${text.slice(0, size)  }...` : text;

export const handleOnEnter = (e: React.KeyboardEvent, fn: () => void) => 
    (e.key === "Enter" || e.key === " ") && fn();

interface Actions {
    enter?: () => void,
    esc?: () => void,
    tab?: () => void,
    shiftTab?: () => void,
};

export const handleOnKeyDown = (e: React.KeyboardEvent, actions: Actions) => {
    Object.entries(actions).forEach(([key, value]) => {
        switch (key) {
            case "enter":
                handleOnEnter(e, value);
                break;
            case "esc":
                if(e.key === "Escape") value();
                break;
            case "tab":
                if (e.key === "Tab" && !e.shiftKey) value();
                break;
            case "shiftTab":
                if (e.key === "Tab" && e.shiftKey) value();
                break;
            default: break;
        }
    });
};
