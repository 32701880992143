import "./index.scss";

import React from "react";

import TextKitLogo from "../../../../ui-components/src/icons/TextKitLogo";

interface LogoProps {
    src: string;
}

const Logo = (props: LogoProps): React.ReactElement => {
    return (
        <div
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "33%",
            }}
        >
            {props.src ? <img className="e-logo" src={props.src} /> : <TextKitLogo className="e-logo" />}
        </div>
    );
};

export default Logo;
