import { THSL, TRGB } from "@/components/whiteLabel/whiteLabel.types";

function hsl2rgb({ hue, saturation, lightness }: THSL): TRGB {
    let rgb;
    hue /= 360;
    saturation /= 100;
    lightness /= 100;

    const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    };

    if (saturation === 0) {
        lightness = Math.round(lightness * 255);
        rgb = {
            red: lightness,
            green: lightness,
            blue: lightness,
        };
    } else {
        const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
        const p = 2 * lightness - q;
        const r = hue2rgb(p, q, hue + 1 / 3);
        const g = hue2rgb(p, q, hue);
        const b = hue2rgb(p, q, hue - 1 / 3);

        rgb = {
            red: Math.round(r * 255),
            green: Math.round(g * 255),
            blue: Math.round(b * 255),
        };
    }

    return rgb;
}

export function getColorBrightness(hsl: THSL): number {
    const rgb = hsl2rgb(hsl);
    // https://www.w3.org/WAI/ER/WD-AERT/#color-contrast
    return (rgb.red * 299 + rgb.green * 587 + rgb.blue * 114) / 1000;
}
