import React from "react";

function Users(props) {
    return (<svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
        <path d="M14.1668 16.5V14.8333C14.1668 13.9493 13.8156 13.1014 13.1905 12.4763C12.5654 11.8512 11.7176 11.5 10.8335 11.5H4.16683C3.28277 11.5 2.43493 11.8512 1.80981 12.4763C1.18469 13.1014 0.833496 13.9493 0.833496 14.8333V16.5" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.49984 8.16667C9.34079 8.16667 10.8332 6.67428 10.8332 4.83333C10.8332 2.99238 9.34079 1.5 7.49984 1.5C5.65889 1.5 4.1665 2.99238 4.1665 4.83333C4.1665 6.67428 5.65889 8.16667 7.49984 8.16667Z" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.1665 16.5001V14.8334C19.166 14.0948 18.9201 13.3774 18.4676 12.7937C18.0152 12.2099 17.3816 11.793 16.6665 11.6084" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.3335 1.6084C14.0505 1.79198 14.686 2.20898 15.1399 2.79366C15.5937 3.37833 15.84 4.09742 15.84 4.83757C15.84 5.57771 15.5937 6.2968 15.1399 6.88147C14.686 7.46615 14.0505 7.88315 13.3335 8.06673" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);
}

export default Users;
