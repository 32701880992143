import "./index.scss";

import React, { FormEvent,ReactFragment, useState } from "react";

import Alert from "@/components/components/Alert";
import Button from "@/components/components/Button";
import { MessageType } from "@/components/enums";
import { TTheme } from "@/components/whiteLabel/whiteLabel.types";

import Heading from "../../components/Heading";
import Logo from "../../components/Logo";
import WorkspaceInput from "../../components/WorkspaceInput";
import { PartnerErrors } from "../../constants/parnerErrors.enum";
import { findWorkspace } from "../../services/workspaceService";

interface PartnerProps {
    theme: TTheme;
}

const Partner = (props: PartnerProps): React.ReactElement => {
    /**
     * State
     * =================================================================================
     */
    const [error, setError] = useState<PartnerErrors | null>(null);
    const [partnerSpace] = useState<string>(window.location.hostname);
    const [workspace, setWorkspace] = useState<string>("");
    const [processing, setProcessing] = useState<boolean>(false);
    const [title] = useState<ReactFragment | string>("find your TextKit workspace");
    const [subtitle] = useState<ReactFragment | string>("enter your team name");

    /**
     * Methods
     * =================================================================================
     */
    const onWorkspaceChange = (ws: string): void => {
        setError(null);
        setWorkspace(ws);
    };

    const onWorkspaceRedirect = (): void => {
        setError(null);
        setProcessing(true);
        findWorkspace(workspace)
            .then((url: URL) => {
                window.location.assign(url.toString());
            })
            .catch(() => {
                setError(PartnerErrors.CAN_NOT_FIND);
                setProcessing(false);
            });
    };

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();
    };

    /**
     * Render
     * =================================================================================
     */
    return (
        <div className="c-partner">
            <Logo src={props.theme?.logotype} />
            <Heading title={title} subtitle={subtitle} />
            {error && (
                <Alert className="c-partner__error" type={MessageType.ERROR}>
                    {error}
                </Alert>
            )}
            <form onSubmit={handleSubmit} className="c-partner__form">
                <WorkspaceInput domain={partnerSpace} placeholder="my-space" onChange={onWorkspaceChange} />
                <Button
                    className="c-partner__button"
                    onClick={onWorkspaceRedirect}
                    disabled={processing || workspace === ""}
                >
                    Continue
                </Button>
            </form>
        </div>
    );
};

export default Partner;
