import { config } from "@/login/config";

export const findWorkspace = (workspace: string): Promise<URL> => {
    return fetch(
      `${config.api.messenger.rest().origin}/auth0?customer=${workspace}&type=messenger`)
      .then(
        async (response): Promise<URL> => {
            const json = await response.json();
            if (json.clientId) {
                return config.workspaceUrl(workspace);
            } 
                throw new Error("unrecognized workspace");
            
        },
    );
};
