export enum ButtonSize {
    BIG = "big",
    REGULAR = "regular",
    SMALL = "small",
}

export enum ButtonColor {
    PRIMARY = "primary",
    SECONDARY = "secondary",   
    RED_INVERSE = "red-inverse",
}

export enum ButtonIconPosition {
    RIGHT = "right",
    LEFT = "left",
}